import { useCallback } from 'react';

export default function Link({ children, path, onClick }) {
  const handler = useCallback(function() {
    if(typeof(onClick) === 'function') {
      return onClick();
    }

    debugger;
  }, [ onClick ]);

  return (
    <a onClick={ handler }>{ children }</a>
  );
};
