import Flex from '@zengym/ui/flex';
import { Outlet } from 'react-router-dom';
import Container from '@zengym/ui/container';

import Nav from './nav';
import Sidebar from './sidebar';

export default function Layout() {
  return (
    <Flex>
      <Sidebar />
      <Flex>
        <Nav />
        <Container>
          <Outlet />
        </Container>
      </Flex>
    </Flex>
  );
};
