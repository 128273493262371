import { useCallback, useMemo } from 'react';

import { useAuth } from '@zengym/auth/client';

import Flex from '@zengym/ui/flex';
import Heading from '@zengym/ui/heading';
import useForm from '@zengym/ui/use-form';
import Form from '@zengym/ui/form';
import FormInput from '@zengym/ui/form-input';
import Button from '@zengym/ui/button';
import Link from '@zengym/ui/link';

export default function Signin({ navigate }) {
  const { signup } = useAuth();

  const { register, onSubmit, disabled, error } = useForm({
    schema: useMemo(function() {
      return {
        email: [ 'required', 'email' ],
        password: [ 'required', 'password' ],
        repeatPassword: [ 'required', 'password', {
          type: 'samePassword',
          args: {
            field: 'password'
          }
        }]
      };
    }, []),
    submit: useCallback(function({ email, password }) {
      return signup({
        email,
        password
      });
    }, [ signup ])
  });

  return (
    <Flex>
      <Heading>Signup</Heading>
      <Form onSubmit={ onSubmit } error={ error }>
        <FormInput { ...register('email') } label='email' type='email' />
        <FormInput { ...register('password') } label='password' type='password' />
        <FormInput { ...register('repeatPassword') } label='repeat password' type='password' />
        <Button disabled={ disabled } type='submit'>Sign Up</Button>
      </Form>
      <span>Do you have an account? <Link onClick={ navigate }>Sign in</Link>!</span>
    </Flex>
  );
};
