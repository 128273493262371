import { useCallback } from 'react';
import { getToken } from '@zengym/auth/client';

export default function(basePath) {
  return useCallback(function(path, body = {}) {
    const fullPath = `${ basePath }${ path }`;

    const token = getToken();

    return fetch(fullPath, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token === null ? undefined : `Bearer ${ token }`
      },
      body: JSON.stringify(body)
    }).then(function(response) {
      if(response.status !== 200) {
        throw new Error(`Request failed: ${ response.status } - ${ response.statusText }`);
      }

      return response.json();
    }).then(function({ data, errors }) {
      if(Array.isArray(errors)) {
        throw new Error(errors[0]);
      }

      return data;
    });
  }, [ basePath ]);
};
