import { useAuth } from '@zengym/auth/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import SplashScreen from '@zengym/ui/splash-screen';
import Auth from './auth';
import Layout from './layout';


export default function Router() {
  const { isSignedin, isLoading } = useAuth();

  if(isLoading === true) {
    return (
      <SplashScreen />
    );
  }

  if(isSignedin === false) {
    return (
      <Auth />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <Layout /> } />
      </Routes>
    </BrowserRouter>
  );
};

