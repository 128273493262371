import Flex from './flex';

export default function Form({ children, onSubmit, error }) {
  return (
    <Flex>
      <span>{ error }</span>
      <form onSubmit={ onSubmit }>
        { children }
      </form>
    </Flex>
  );
};
