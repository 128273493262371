import { AuthProvider } from '@zengym/auth/client';
import Router from './router';

export default function App() {
  return (
    <AuthProvider>
      <Router />
    </AuthProvider>
  );
};
