import Flex from './flex';

export default function FormInputWrapper({ children, name, label, error }) {
  return (
    <Flex>
      <label htmlFor={ name }>{ label }</label>
      { children }
      <span>{ error }</span>
    </Flex>
  );
};
