import FormInputWrapper from './form-input-wrapper';
import Input from './input';

export default function FormInput({ type, value, onChange, label, name, error }) {
  return (
    <FormInputWrapper name={ name } label={ label } error={ error } >
      <Input value={ value } type={ type } onChange={ onChange } name={ name } />
    </FormInputWrapper>
  );
};
