import { useState, useMemo } from 'react';

import Container from '@zengym/ui/container';
import Logo from '@zengym/ui/logo';
import Flex from '@zengym/ui/flex';

import Signin from './signin';
import Signup from './signup';

export default function Auth() {
  const [ currentView, setCurrentView ] = useState('SIGNIN');

  const widget = useMemo(function() {
    if(currentView === 'SIGNIN') {
      const navigate = function() {
        setCurrentView('SIGNUP');
      };

      return (
        <Signin navigate={ navigate } />
      );
    }

    const navigate = function() {
      setCurrentView('SIGNIN');
    };

    return (
      <Signup navigate={ navigate } />
    );
  }, [ currentView ]);

  return (
    <Container>
      <Flex>
        <Logo />
        { widget }
      </Flex>
    </Container>
  );
};
