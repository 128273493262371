import { useMemo, useCallback } from 'react';

import { useAuth } from '@zengym/auth/client';

import Flex from '@zengym/ui/flex';
import Heading from '@zengym/ui/heading';
import useForm from '@zengym/ui/use-form';
import Form from '@zengym/ui/form';
import FormInput from '@zengym/ui/form-input';
import Button from '@zengym/ui/button';
import Link from '@zengym/ui/link';

export default function Signin({ navigate }) {
  const { signin } = useAuth();

  const { register, onSubmit, disabled, error } = useForm({
    schema: useMemo(function() {
      return {
        email: [ 'required', 'email' ],
        password: [ 'required', 'password' ]
      };
    }, []),
    submit: useCallback(function({ email, password }) {
      return signin({
        email,
        password
      });
    }, [ signin ])
  });

  return (
    <Flex>
      <Heading>Signin</Heading>
      <Form onSubmit={ onSubmit } error={ error }>
        <FormInput { ...register('email') } label='email' type='email' />
        <FormInput { ...register('password') } label='password' type='password' />
        <Button disabled={ disabled } type='submit'>Sign In</Button>
      </Form>
      <span>New to Zengym? <Link onClick={ navigate }>Sign up</Link>!</span>
    </Flex>
  );
};
