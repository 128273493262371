import { useMemo, useCallback, useState, useLayoutEffect } from 'react';
import useValidation from '@zengym/validate/client'

export default function({ submit, schema = {}, defaults = {} }) {
  const [ values, setValues ] = useState(defaults);
  const [ errors, setErrors ] = useState({});
  const [ formError, setFormError ] = useState();
  const [ isLoading, setIsLoading ] = useState(false);
  const [ isValid, setIsValid ] = useState(false);
  const [ isDirty, setIsDirty ] = useState(false);

  const validate = useValidation(schema);

  useLayoutEffect(function() {
    if(isDirty === false) {
      return;
    }

    validate(values).then(function(errors) {
      if(Object.keys(errors).length === 0) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }

      setErrors(errors);
    });
  }, [ validate, values ]);

  const onSubmit = useCallback(function(evt) {
    setIsLoading(true);
    evt.preventDefault();

    Promise.resolve().then(function() {
      return submit(values);
    }).catch(function(error) {
      setFormError(error.message);
    }).finally(function() {
      setIsLoading(false);
    });
  }, [ submit, values ]);

  const register = useCallback(function(name) {
    return {
      value: values[name],
      onChange: function({ target }) {
        setIsDirty(true);
        const { value } = target;

        setValues({
          ...values,
          [name]: value
        });
      },
      name,
      error: errors[name]
    };
  }, [ values, errors ]);

  return useMemo(function() {
    return {
      register,
      onSubmit,
      disabled: isLoading === true || isValid === false,
      isValid,
      isLoading,
      error: formError,
      isDirty
    };
  }, [ register, onSubmit, isLoading, isValid, formError, isDirty ]);
};
