import { useState, createContext, useContext, useMemo, useLayoutEffect } from 'react';
import { getToken, setToken, deleteToken } from './token';
import http from '@zengym/http/client';

const Context = createContext();

export const useAuth = function() {
  return useContext(Context);
};

export const AuthProvider = function({ children }) {
  const [ isLoading, setIsLoading ] = useState(true);
  const [ isSignedin, setIsSignedin ] = useState(false);

  const client = http('/api/auth');

  useLayoutEffect(function() {
    const token = getToken();

    if(token == null) {
      setIsSignedin(false);
    } else {
      setIsSignedin(true);
    }

    setIsLoading(false);
  }, []);

  const value = useMemo(function() {
    return {
      isLoading,
      isSignedin,
      signin: function({ email, password }) {
        return client('/signin', {
          email,
          password
        }).then(function({ accessToken }) {
          setToken(accessToken);
          setIsSignedin(true);
        });
      },
      signup: function({ email, password }) {
        return client('/signup', {
          email,
          password
        }).then(function({ accessToken }) {
          setToken(accessToken);
          setIsSignedin(true);
        });
      },
      signout: function() {
        deleteToken();
      }
    };
  }, [ isLoading, isSignedin, client ]);

  return (
    <Context.Provider value={ value }>
      { children }
    </Context.Provider>
  );
};
