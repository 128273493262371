import { createRoot } from 'react-dom/client';

import '@gloveup/theme';

export default function(app) {
  const node = document.querySelector('#root');
  const root = createRoot(node);

  root.render(app);
};
