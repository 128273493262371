const validators = {
  required: function(value, args, data) {
    if(value != null) {
      return;
    }

    return 'Value cannot be empty';
  },
  email: function(value, args, data) {
    const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

    if(regex.test(value) === true) {
      return;
    }

    return 'Email is not valid';
  },
  password: function(value, args, data) {
    if(value.length >= 7) {
      return;
    }


    return 'Password should contain at least 7 characters';
  },
  samePassword: function(value, { field }, data) {
    if(value === data[field]) {
      return;
    }

    return `Passwords must match`;
  }
};

export default function(schema, values) {
  return Promise.resolve().then(function() {
    return Object.keys(schema).reduce(function(errors, field) {
      schema[field].every(function(check) {
        let args = {};

        if(typeof(check) === 'object') {
          args = check.args || {};
          check = check.type;
        }

        const result = validators[check](values[field], args, values);

        if(result == null) {
          return true;
        }

        errors[field] = result;
        return false;
      });

      return errors;
    }, {});
  });
};
