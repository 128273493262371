const TOKEN_KEY = 'fkwjbfwejnfienfi32oniend932ioh3foiqefj932inefhnoiewhfho32ih9o32ur';

export const getToken = function() {
  return localStorage.getItem(TOKEN_KEY);
};

export const setToken = function(token) {
  localStorage.setItem(TOKEN_KEY, token);
};

export const deleteToken = function() {
  localStorage.deleteItem(TOKEN_KEY);
};
